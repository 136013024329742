@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  white-space: pre-line;
}

.nav-bar {
  background: black;
  width: 100%;
  height: 80px;
  display: flex;
  color: white;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;

}

.line_delete {
  text-decoration-line: none;
  color: black;
}

.list {
  margin-top: 30px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

* {
  font-family: "TTHoves", "NanumSquare", "나눔스퀘어", "Helvetica Neue", "Apple SD Gothic Neo", Apple SD Gothic Neo, -apple-system, BlinkMacSystemFont, Malgun Gothic, "돋움", dotum, arial, sans-serif;
}



/* Quill */

/* Quill 에디터 */
.ql-toolbar {
	border-radius: 0.5em 0.5em 0 0;
}
.ql-container {
  min-height: 200px;
	border-radius: 0 0 0.5em 0.5em;
	font-size: 15.4px;
}
.quill-container>div:not(:nth-last-child(-n+2)) {
  display: none;
}
.quill-container>div:last-child {
  display: block;
}

/* 갤러리 스타일 일부 */
.gallery-item .text-background {
  background-color: rgba(0, 0, 0, 0.6);
}
